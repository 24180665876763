<template lang="pug">
div
  ui-button(@buttonClicked="showFeedback" )
    span {{ehrText.StudentFeedbackButton}}
</template>

<script>
import UiButton from '@/app/ui/UiButton.vue'
import { t18EhrText } from '@/helpers/ehr-t18'

export default {
  components: { UiButton },
  computed: {
    ehrText () { return t18EhrText()}
  },
  methods: {
    showFeedback: function () {
      this.$store.dispatch('system/setEvalDialogVisible', true)
    },
  },
}
</script>
