<template lang="pug">
div
  ui-button(@buttonClicked="showInstructions" ) {{ehrText.StudentInstructionsButton}}
</template>

<script>
import UiButton from '@/app/ui/UiButton.vue'
import { t18EhrText } from '@/helpers/ehr-t18'

export default {
  components: { UiButton },
  computed: {
    ehrText () { return t18EhrText()}
  },
  methods: {
    showInstructions: function () {
      // See EhrInstructionDialog
      // See EhrLayout for the placement of the dialog
      this.$store.dispatch('system/setDialogInstructionsVisible', true)
    }
  }
}
</script>
