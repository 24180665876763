import { render, staticRenderFns } from "./UiNotSignedOn.vue?vue&type=template&id=01b98b20&scoped=true&lang=pug"
import script from "./UiNotSignedOn.vue?vue&type=script&lang=js"
export * from "./UiNotSignedOn.vue?vue&type=script&lang=js"
import style0 from "./UiNotSignedOn.vue?vue&type=style&index=0&id=01b98b20&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b98b20",
  null
  
)

export default component.exports