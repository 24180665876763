// noinspection DuplicatedCode

export const DATA_MATCHES = 'data_matches'
export const MISSING_IN_COMPARE = 'missing_in_compare'
export const MISSING_IN_EHR = 'missing_in_ehr'
export const ROW_DIFFERENCES = 'row_differences'

/**
 * Returns a pgStats object
 * lastUpdate -- taken from the ehrData
 * hasData -- true if the ehrData has
 * compareHasData --- true if the compare data has data
 *
 * pageDifferences is an object that is empty if the page form data in ehrData matches the data in compareData.  Otherwise, each field that is different is represented. For example,
 "pageDifferences": {
        "status": {
          "ehrValue": "Outpatient",
          "compareValue": "Inpatient"
        },
        "consentForTreatment": {
          "status": MISSING_IN_EHR,
          "compareValue": true
        },
        "consentForBlood": {
          "status": MISSING_IN_COMPARE,
          "ehrValue": true
        }
      },


 * tableDifferences is an object that has an object for each page table. The key is the tableKey.
 * Each table object has an element for each row that is in either data set.
 * The rowId is the element's key.
 * Each element has a status property
 * If MISSING_IN_EHR the element includes the 'compareRow'
 * If MISSING_IN_COMPARE the element includes the 'ehrRow'
 * If ROW_DIFFERENCES the element contains a 'rowDifferences' property that contains elements
 * keyed by elementKey that contain the ehrValue and the compareValue
 * If DATA_MATCHES the element just has the status

 *
 *
 * @param ehrData
 * @param compareData
 * @param pageTables
 * @param formKeys
 * @returns {{lastUpdate: *, hasData: boolean, tableDifferences: {}, pageDifferences: {}, compareHasData: boolean}}
 */
export function ehrPageStatsWithCompare (pageKey, ehrData, compareData, pageTables, formKeys) {
  let pgStats = {
    pageKey: pageKey,
    lastUpdate: ehrData && ehrData.lastUpdate,
    hasData: false,
    compareHasData: false,
    hasDifference: false,
    pageDifferences: {},
    tableDifferences: {}
  }

  // console.log('formKeys', formKeys)
  lookForDraft (pgStats, ehrData, pageTables)

  // Check for page differences
  comparePageForm(pgStats, ehrData,  compareData, formKeys)
  if (objectHasContent(pgStats.pageDifferences)) {
    pgStats.hasDifference = true
  }
  // Check for tables and rows
  compareTableData(pgStats, ehrData, compareData, pageTables)
  if (!pgStats.hasDifference) {
    for (const tbl in pgStats.tableDifferences) {
      for (const row in tbl) {
        if (row.status && row.status !== DATA_MATCHES) {
          console.log('row has diff', row)
          pgStats.hasDifference = true
          break
        }
      }
    }
  }
  // if (pgStats.hasDifference) {
  //   console.log('Page stats', JSON.stringify(pgStats, null, 2))
  // }
  return pgStats
}

function comparePageForm (pgStats, ehrData, compareData, formKeys) {
  if (ehrData) {
    pgStats.hasData = true

    if (compareData) {
      pgStats.compareHasData = true
      const ehrFormData = cloneNotEmptyProperties(ehrData, formKeys)
      const compareFormData = cloneNotEmptyProperties(compareData, formKeys)
      // console.log('ehrFormData', ehrFormData)
      // console.log('compareFormData', compareFormData)

      // Check for missing keys in ehrData
      formKeys.forEach((key) => {
        let a = ehrFormData[key]
        let b = compareFormData[key]
        if (a && !b) {
          pgStats.pageDifferences[key] = { status: MISSING_IN_COMPARE, ehrValue: a }
        } else if (!a && b) {
          pgStats.pageDifferences[key] = { status: MISSING_IN_EHR, compareValue: b }
        } else if (a !== b) {
          pgStats.pageDifferences[key] = { ehrValue: a, compareValue: b }
        }
      })
    }
  }
}

function compareTableData (pgStats, ehrData, compareData, pageTables) {
  if (ehrData) {
    const pageTableKeys = pageTables.map((pt) => pt.key)
    if (pageTableKeys.length > 0) {
      // Initialize differences for each table
      pageTableKeys.forEach((key) => {
        const tData = ehrData[key] || []
        const cData = compareData ? compareData[key] || [] : []
        const rowDiffs = {}

        // Compare rows in ehrData with rows in compareData
        tData.forEach((row) => {
          const rowId = row[`${key}_id`] // Assuming IDs are structured like `<table_key>_id`
          const compareRow = cData.find((r) => r[`${key}_id`] === rowId)
          if (compareRow) {
            const rowDifferences = {}
            Object.keys(row).forEach((elementKey) => {
              // Exclude specific fields from comparison
              const excludeFields = [
                `${key}_name`,
                `${key}_profession`,
                'createdDate',
                'isDraft'
              ]

              if (!excludeFields.includes(elementKey) && row[elementKey] !== compareRow[elementKey]) {
                rowDifferences[elementKey] = {
                  ehrValue: row[elementKey],
                  compareValue: compareRow[elementKey]
                }
              }
            })
            if (Object.keys(rowDifferences).length > 0) {
              rowDiffs[rowId] = {
                status: ROW_DIFFERENCES,
                rowDifferences: rowDifferences
              }
            } else {
              rowDiffs[rowId] = {
                status: DATA_MATCHES
              }
            }
          } else {
            // Row is missing in compareData
            rowDiffs[rowId] = {
              status: MISSING_IN_COMPARE,
              ehrRow: row
            }
          }
        })
        // Check for rows in compareData that are missing in ehrData
        cData.forEach((compareRow) => {
          const compareRowId = compareRow[`${key}_id`]
          if (!tData.some((row) => row[`${key}_id`] === compareRowId)) {
            // Copy missing rows from compareData
            rowDiffs[compareRowId] = {
              status: MISSING_IN_EHR,
              compareRow: compareRow
            }
          }
        })

        pgStats.tableDifferences[key] = rowDiffs
      })
    }
  }
}

function lookForDraft (pgStats, ehrData, pageTables) {
  if (ehrData) {
    const pageTableKeys = pageTables.map((pt) => pt.key)
    if (pageTableKeys.length > 0) {
      // Initialize differences for each table
      pageTableKeys.forEach((key) => {
        const tData = ehrData[key] || []
        // look for draft rows in ehrData. If any row isDraft then flag at the top level
        tData.forEach((row) => {
          if (row.isDraft) {
            pgStats.hasDraft = true
            // could stop here but there is so little data it's cleaner to just run through everything
          }
        })
      })
    }
  }
}

function cloneNotEmptyProperties (obj, pKeys) {
  if (!obj) return {} // If obj is undefined or null, return an empty object

  const clone = {}
  pKeys.forEach((k) => {
    const val = obj[k]
    if (val !== undefined && val !== null && val !== '' && !Array.isArray(val)) {
      clone[k] = val
    }
  })
  return clone
}

function objectHasContent ( obj ) {
  return JSON.stringify(obj).trim().length > 2 // /i.e. does not equal {}
}
